import React, { Component } from 'react';
import Footer from '../../components/common/footer/Footer';
import LoadingSpinner from '../../components/common/LoadingSpinner';
import { getVideosByCategory } from '../../services/http/videoService';

export default class Videos extends Component {
  clientsRef = React.createRef();

  state = {
    categoryId: 0,
    videos: [],
    isLoading: true,
    error: '',
    currentYoutubeLink: '',
    currentVimeoLink: '',
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const categoryId = this.props.match.params.categoryId;
    getVideosByCategory(categoryId)
      .then((res) => this.onVideosSuccessfull(res))
      .catch((err) => this.onVideosError(err));
    this.setState({ categoryId });
  }

  onVideosSuccessfull = (res) => {
    this.setState({ isLoading: false, videos: res.result });
  };

  onVideosError = (error) => {
    this.setState({ isLoading: false, error: error.message });
  };

  handleScrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    const el = elRef.current ? elRef.current : elRef;

    // Scroll the element into view
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  onBackClick = (e) => {
    e.preventDefault();
    this.props.history.push({
      pathname: '/home',
    });
  };

  onVideoClick = (e, video) => {
    e.preventDefault();
    const { link } = video;
    window.open(link, '_blank');
    window.focus();
  };

  onThumbClick = (e, video) => {
    e.preventDefault();
    this.setState({ currentVimeoLink: video.vimeo.link, currentYoutubeLink: video.youtube.link });
  };

  resolveVideos = (isLatest) => {
    let videos = this.state.videos;

    const sortedVideos = videos.sort((a, b) => {
      if (isLatest) {
        return new Date(b.youtube.createdAt) - new Date(a.youtube.createdAt);
      } else {
        return new Date(a.youtube.createdAt) - new Date(b.youtube.createdAt);
      }
    });

    return sortedVideos.map((video) => {
      return (
        <div class="col-lg-3 col-md-4">
          <div
            data-toggle="modal"
            data-target="#videoModal"
            class="thumb-container"
            onClick={(e) => {
              this.onThumbClick(e, video);
            }}
          >
            <div class="img-overlay"></div>
            <img src={video.youtube.thumbLink2} class="img-fluid"></img>
            <div class="over-text">
              <p>{video.youtube.name}</p>
            </div>
          </div>
        </div>
      );
    });
  };

  render() {
    return (
      <div style={{ backgroundColor: '#f7f7f7' }}>
        <div class="container-fluid videos-container pl-5 pr-5">
          <br></br>
          <br></br>
          <h1 class="display-1 text-center">sloboda.plus</h1>
          <h4 class="text-center" style={{ color: '#616161' }}>
            sa dr. Semirom Osmanagićem
          </h4>
          <br></br>
          <br></br>
          <div class="categories">
            <h4
              class="go-back"
              onClick={(e) => {
                this.onBackClick(e);
              }}
            >
              ← nazad na početnu
            </h4>
            {this.state.isLoading && <LoadingSpinner text="Učitivanje videa, molimo pričekajte..." height={50} width={50} />}
            {!this.state.isLoading && (
              <div>
                <ul class="nav nav-tabs" id="myTab" role="tablist">
                  <h3>Poredaj po</h3>
                  <li class="nav-item">
                    <a class="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">
                      najnovijim
                    </a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">
                      najstarijim
                    </a>
                  </li>
                </ul>
                <div class="tab-content" id="myTabContent">
                  <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                    <div class="container-fluid">
                      <div class="row">{this.resolveVideos(true)}</div>
                    </div>
                  </div>
                  <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                    <div class="container-fluid">
                      <div class="row">{this.resolveVideos(false)}</div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>

        <div class="modal fade" id="videoModal" tabindex="-1" aria-labelledby="videoModal" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="exampleModalLabel">
                  Odaberite platformu za gledanje
                </h5>
              </div>
              <div class="modal-body text-center">
                <br></br>
                <h2>
                  <a target="_blank" href={this.state.currentYoutubeLink} style={{ color: 'rgb(255 12 12)' }}>
                    Youtube
                  </a>
                </h2>
                <br></br>
                <h2>
                  <a target="_blank" href={this.state.currentVimeoLink} style={{ color: 'rgb(143 207 243)' }}>
                    Vimeo
                  </a>
                </h2>
                <br></br>
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-primary" data-dismiss="modal">
                  Zatvori
                </button>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
