import React, { Component } from 'react';

export default class NotFound extends Component {
  racesRef = React.createRef();

  state = {
    news: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  render() {
    return (
      <div>
        <section id="hero" style={{ height: '0px', marginBottom: '0px' }}>
          <div className="hero-container" className="home"></div>
        </section>

        <main id="main" style={{ backgroundColor: 'white' }}>
          <section id="clients" class="clients" style={{ backgroundColor: 'rgb(241, 241, 241)', paddingTop: '200px', paddingBottom: '170px' }}>
            <div class="container">
              <div class="section-title">
                <h1 style={{ fontSize: '80px' }}>404</h1>
              </div>

              <div class="section-title">
                <h4>Oops! The selected page does not exist</h4>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}
