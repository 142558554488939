import React, { Component } from 'react';
import Intervjui from '../../assets/img/intervjui.png';
import Joni from '../../assets/img/joni.png';
import Novak from '../../assets/img/novak.png';
import Polemike from '../../assets/img/polemike.png';
import Setnje from '../../assets/img/setnje.png';
import Vijesti from '../../assets/img/vijesti.png';
import Footer from '../../components/common/footer/Footer';

export default class Home extends Component {
  clientsRef = React.createRef();

  state = {
    news: [],
  };

  componentDidMount() {
    window.scrollTo(0, 0);
  }

  handleScrollTo = (elRef) => {
    // Incase the ref supplied isn't ref.current
    const el = elRef.current ? elRef.current : elRef;

    // Scroll the element into view
    el.scrollIntoView({
      behavior: 'smooth',
      block: 'start',
    });
  };

  onCategoryClick = (e, categoryId) => {
    e.preventDefault();
    this.props.history.push({
      pathname: '/videos/' + categoryId,
    });
  };

  render() {
    return (
      <div style={{ backgroundColor: '#f7f7f7' }} class="main-container">
        <div class="container-fluid home-container">
          <br></br>
          <br></br>
          <h1 class="display-1 text-center">sloboda.plus</h1>
          <h4 class="text-center" style={{ color: '#616161' }}>
            sa dr. Semirom Osmanagićem
          </h4>
          <br></br>
          <br></br>
          <div class="categories">
            <h3 style={{ fontWeight: 'bold' }}>Odaberite kategoriju</h3>
            <hr></hr>
            <div class="row">
              <div class="col-lg-4 col-md-6">
                <div class="img-container" onClick={(e) => this.onCategoryClick(e, 1)}>
                  <div class="img-overlay"></div>
                  <img src={Intervjui} class="img-fluid"></img>
                  <div class="over-text">
                    <h1>INTERVJUI</h1>
                    <p>protiv ne-slobode</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="img-container" onClick={(e) => this.onCategoryClick(e, 2)}>
                  <div class="img-overlay"></div>
                  <img src={Joni} class="img-fluid"></img>
                  <div class="over-text">
                    <h1>JONIZIRANJE</h1>
                    <p>protiv virusa</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="img-container" onClick={(e) => this.onCategoryClick(e, 3)}>
                  <div class="img-overlay"></div>
                  <img src={Novak} class="img-fluid"></img>
                  <div class="over-text">
                    <h1>NOVAK</h1>
                    <p>protiv ne-saradnje</p>
                  </div>
                </div>
              </div>

              <br></br>

              <div class="col-lg-4 col-md-6">
                <div class="img-container" onClick={(e) => this.onCategoryClick(e, 4)}>
                  <div class="img-overlay"></div>
                  <img src={Vijesti} class="img-fluid"></img>
                  <div class="over-text">
                    <h1>VIJESTI</h1>
                    <p>protiv cenzure</p>
                  </div>
                </div>
              </div>
              <div class="col-lg-4 col-md-6">
                <div class="img-container" onClick={(e) => this.onCategoryClick(e, 5)}>
                  <div class="img-overlay"></div>
                  <img src={Setnje} class="img-fluid"></img>
                  <div class="over-text">
                    <h1>ŠETNJE</h1>
                    <p>protiv neznanja</p>
                  </div>
                </div>
              </div>

              <div class="col-lg-4 col-md-6">
                <div class="img-container" onClick={(e) => this.onCategoryClick(e, 6)}>
                  <div class="img-overlay"></div>
                  <img src={Polemike} class="img-fluid"></img>
                  <div class="over-text">
                    <h1>POLEMIKE</h1>
                    <p>protiv mržnje</p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <br></br>

          <div class="more-reading-container">
            <h3 style={{ fontWeight: 'bold' }}>Daljnje čitanje</h3>
            <hr></hr>

            <div classs="row">
              <h3 class="modal-link" data-toggle="modal" data-target="#ustavniModal">
                ➤ Ustavni sud Bosne i Hercegovine
              </h3>
              <h3 class="modal-link" data-toggle="modal" data-target="#konvencijaModal">
                ➤ Evropska konvencija o ljudskim pravima i osnovnim slobodama
              </h3>
            </div>
          </div>

          <div class="modal fade" id="ustavniModal" tabindex="-1" aria-labelledby="ustavniModal" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Ustavni sud Bosne i Hercegovine
                  </h5>
                </div>
                <div class="modal-body">
                  <b>|22.12.2020.</b>
                  <br></br>
                  Ustavni sud Bosne i Hercegovine je danas elektronskim putem održao redovnu sjednicu Velikog vijeća Ustavnog suda Bosne i Hercegovine na kojoj
                  je donesena odluka da mjera ograničenja kretanja i nošenja maski tokom pandemije novog koronavirusa predstavlja kršenje osnovnih ljudskih
                  prava, prenosi Anadolu Agency (AA). <br></br>
                  <br></br>
                  Ustavni sud zaključio je da miješanje u osnovna ljudska prava i slobode garantirane Ustavom Bosne i Hercegovine i Evropskom konvencijom za
                  zaštitu ljudskih prava i osnovnih sloboda (Evropska konvencija), u konkretnom slučaju prava na privatni život i na slobodu kretanja, koje je
                  izvršeno naredbama uskih segmenata izvršne vlasti o obaveznom nošenju zaštitnih maski i o ograničenju kretanja, u konkretnom slučaju kriznih
                  štabova ministarstava zdravstva, kada je izostalo aktivno učešće u donošenju i preispitivanju naređenih mjera od strane najviših organa
                  zakonodavne i izvršne vlasti, predstavlja kršenje navedenih ljudskih prava i sloboda.
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" data-dismiss="modal">
                    Zatvori
                  </button>
                </div>
              </div>
            </div>
          </div>

          <div class="modal fade" id="konvencijaModal" tabindex="-1" aria-labelledby="konvencijaModal" aria-hidden="true">
            <div class="modal-dialog">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="exampleModalLabel">
                    Evropska konvencija o ljudskim pravima i osnovnim slobodama
                  </h5>
                </div>
                <div class="modal-body">
                  Vlade potpisnice ove Konvencije, kao članice Savjeta Evrope imajući u vidu Univerzalnu deklaraciju o ljudskim pravima koju je i Generalna
                  skupština Ujedinjenih nacija proglasila 10. decembra 1948:
                  <br></br>
                  <br></br>
                  <b>Član 2.</b>
                  <br></br>
                  1. Pravo na život svake osobe zaštićeno je zakonom. Niko ne može biti namjerno lišen života.
                  <br></br>
                  <br></br>
                  <b>Član 10.</b>
                  <br></br>
                  1. Svako ima pravo na slobodu izražavanja. Ovo pravo uključuje slobodu posjedovanja sopstvenog mišljenja, primanja i saopštavanja informacija
                  i ideja bez miješanja javne vlasti i bez obzira na granice.
                  <br></br>
                  <br></br>
                  <b>Član 11.</b>
                  <br></br>
                  1. Svako ima pravo na slobodu mirnog okupljanja i slobodu udruživanja s drugima.
                </div>
                <div class="modal-footer">
                  <button type="button" class="btn btn-primary" data-dismiss="modal">
                    Zatvori
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer></Footer>
      </div>
    );
  }
}
