import React, { Component } from 'react';
import { IntlProvider } from 'react-intl';
import { Redirect, Route, Switch } from 'react-router-dom';
import { HOME_ROUTE, NOT_FOUND_ROUTE, VIDEOS_ROUTE } from './constants/routes';
import Home from './pages/home/Home';
import NotFound from './pages/NotFound';
import Videos from './pages/videos/Videos';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <IntlProvider>
          <main>
            <Switch>
              <Route exact path={NOT_FOUND_ROUTE} component={NotFound} />
              <Route exact path={HOME_ROUTE} component={Home} />
              <Route exact path={VIDEOS_ROUTE} component={Videos} />

              <Redirect from="/" exact to={HOME_ROUTE} />
              <Redirect to={NOT_FOUND_ROUTE} />
            </Switch>
          </main>
        </IntlProvider>
      </React.Fragment>
    );
  }
}

export default App;
